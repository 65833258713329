import Head from 'components/head'
import Layout from 'layouts'
import NotFoundPage from 'components/pages/notFoundPage'
import React from 'react'
import { isWebBrowser } from 'utils'
import { metaTagData } from 'config'

const Page = (): React.ReactElement | null => {
  /*if (!isWebBrowser()) {
    return null
  }*/
  return (
    <>
      <Head metaTagData={metaTagData} />
      <Layout>
        <NotFoundPage />
      </Layout>
    </>
  )
}

export default Page
