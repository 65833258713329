import React, { ReactElement } from 'react'

// import HomeButton from 'components/buttons/homeBtn'
import { Link } from 'gatsby'
import Masthead from 'components/mastHead'
import img from './masthead.jpg'

/*
.errorCode {
  font-size: 4rem;
  font-weight: bolder;
  @media (min-width: 576px){
          font-size: 7rem;
      }
}
*/

//style={{ minHeight: 'calc(100vh/2)' }}
export default function index(): ReactElement {
  return (
    <>
      <Masthead backgroundImage={img}>
        <h1 className="lg:text-6xl text-5xl font-light text-center">Oops!</h1>
      </Masthead>
      <div className="animated fadeIn text-lg text-center text-gray-700 py-20 bg-white flex-grow flex flex-col justify-center align-items-center">
        <div className="">
          {/*<p style={{ fontSize: '5em' }} className="mt-4 font-light">
            404
  </p>*/}
          <p className="mt-4 text-4xl font-light">The page you&apos;re looking for is not found.</p>
        </div>
        <div className="py-10">
          <p>
            Make sure the address is correct.
            <br />
            If you think this is a mistake,
            <a href="mailto:rtorcato@me.com" className="ml-1">
              contact us
            </a>
            .
          </p>
          <p className="flex flex-column justify-center mt-10 px-4">
            <Link className="btn btn-secondary lift lg:w-1/2" to="/">
              Take me home
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}
